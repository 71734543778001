export default function SpeakerSVG({ isActive, color }) {

    let contourColor = color;//isActive ? "#222051" : "#C0C0C0";
    let backgtoundColor = color;// isActive ? "#4EFFBB" : "#A9A9A9";
    let speakerColor =color;

    return (
        <svg 
        height="100px" 
        width="100px" 
        version="1.1" 
        id="Layer_1" 
        xmlns="http://www.w3.org/2000/svg" 
        //xmlns:xlink="http://www.w3.org/1999/xlink" 
        viewBox="0 0 268.599 268.599" 
        //xml:space="preserve"
        >
            <g>
                <g>
                    <g>
                        <path style={{fill:backgtoundColor}} d="M227.2,20.92H64.482c-3.917,0-7.094,3.232-7.094,7.176v227.602c0,3.971,3.177,7.176,7.094,7.176
                            h7.97v3.506c0,1.232,0.986,2.219,2.191,2.219h20.542c1.233,0,2.219-0.986,2.219-2.219v-3.506h97.148v3.588
                            c0,1.178,0.959,2.136,2.136,2.136h20.679c1.178,0,2.136-0.959,2.136-2.136v-3.588h7.696c3.944,0,7.094-3.204,7.094-7.176V28.096
                            C234.294,24.152,231.144,20.92,227.2,20.92z"/>
                    </g>
                    <g>
                        <g id="XMLID_14_">
                            <g>
                                <g>
                                    <path style={{fill:speakerColor}} d="M197.049,227.602H48.493V14.352H197.05L197.049,227.602L197.049,227.602z M126.141,220.097
                                        c29.388,0,53.299-24.184,53.299-53.901c0-29.744-23.911-53.929-53.299-53.929s-53.299,24.184-53.299,53.929
                                        C72.842,195.913,96.751,220.097,126.141,220.097z M188.148,35.441c0-5.231-4.191-9.449-9.367-9.449s-9.367,4.218-9.367,9.449
                                        s4.19,9.477,9.367,9.477S188.148,40.673,188.148,35.441z M163.635,63.789c0-20.925-16.817-37.961-37.495-37.961
                                        S88.617,42.864,88.617,63.789s16.844,37.934,37.523,37.934S163.635,84.714,163.635,63.789z"/>
                                </g>
                                <g>
                                    <path style={{fill:contourColor}} d="M211.21,7.176v227.602c0,3.971-3.15,7.176-7.094,7.176h-7.696v3.588
                                        c0,1.178-0.959,2.136-2.136,2.136h-20.679c-1.178,0-2.136-0.959-2.136-2.136v-3.588H74.32v3.506
                                        c0,1.232-0.986,2.218-2.218,2.218H51.561c-1.205,0-2.191-0.986-2.191-2.218v-3.506H41.4c-3.917,0-7.094-3.204-7.094-7.176
                                        V7.176C34.306,3.232,37.482,0,41.398,0h162.717C208.061,0,211.21,3.232,211.21,7.176z M197.049,227.602V14.352H48.493v213.25
                                        H197.049z"/>
                                </g>
                                <g>
                                    <path style={{fill:contourColor}} d="M126.141,112.267c29.388,0,53.299,24.184,53.299,53.929c0,29.717-23.911,53.901-53.299,53.901
                                        s-53.299-24.184-53.299-53.901C72.842,136.451,96.751,112.267,126.141,112.267z M165.252,166.196
                                        c0-21.829-17.556-39.577-39.111-39.577c-21.582,0-39.139,17.748-39.139,39.577c0,21.802,17.556,39.55,39.139,39.55
                                        C147.696,205.745,165.252,187.997,165.252,166.196z"/>
                                </g>
                                <g>
                                    <path style={{fill:contourColor}} d="M126.141,25.828c20.679,0,37.495,17.036,37.495,37.961s-16.817,37.934-37.495,37.934
                                        S88.617,84.714,88.617,63.789S105.462,25.828,126.141,25.828z M149.448,63.789c0-13.01-10.463-23.609-23.308-23.609
                                        c-12.873,0-23.335,10.6-23.335,23.609s10.463,23.582,23.335,23.582C138.986,87.371,149.448,76.798,149.448,63.789z"/>
                                </g>
                                <g>
                                    <path style={{fill:contourColor}} d="M126.141,144.339c11.914,0,21.61,9.778,21.61,21.856c0,12.051-9.696,21.856-21.61,21.856
                                        c-11.942,0-21.61-9.805-21.61-21.856C104.531,154.117,114.199,144.339,126.141,144.339z"/>
                                </g>
                                <g>
                                    <path style={{fill:contourColor}} d="M133.48,56.339c4.054,4.108,4.054,10.764,0,14.872s-10.627,4.108-14.68,0
                                        c-4.081-4.108-4.081-10.764,0-14.872C122.854,52.258,129.426,52.258,133.48,56.339z"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
  }